/* eslint-disable import/no-named-as-default-member */
import { createTheme } from '@material-ui/core/styles'
import typography from './variables/typography'
import colors from './variables/colors'
import { concordeBq } from './variables/concordeBQ'

const {
  fontFamily,
  fontWeights,
  fontSizes,
  lineHeights,
  letterSpacing,
  paragraphSpacing,
} = typography

const properties = {
  palette: {
    // use color='primary' for OCEAN BLUE
    primary: {
      hue: '209',
      background: 'hsl(209, 78%, 16%, 1)',
      base: colors.BLUE_BASE,
      contrast_text: colors.GRAY_SCALE_0,
      text_dark: 'hsl(209, 78%, 16%, 1)',
      text_light: '#BBD6EF',
      main: 'hsl(209, 57%, 34%)',
      light: 'hsla(200, 31%, 94%, 1)',
      dark: 'hsl(209, 81%, 14%)',
      darkBackground: 'hsla(209, 90%, 54%, 0.08)',
      lightBackground60: 'rgba(9, 42, 72, 0.6)',
      selected: 'hsla(209, 90%, 54%, 0.08)',
    },

    // use color='secondary' for GOLD
    secondary: {
      hue: '43',
      base: colors.GOLDEN_BASE,
      contrast_text: colors.WHITE,
      text_dark: 'hsl(43, 90%, 43%)',
      text_light: '#D8B45ACC',
      text_light30: '#D8B45A4D',
      main: '#D8B45A',
      light: 'hsl(43, 89%, 82%)',
      dark: 'hsl(43, 92%, 25%)',
      background: 'hsla(43, 90%, 54%, 0.08)',
      selected: 'hsla(43, 90%, 54%, 0.08)',
      border: 'hsla(43, 90%, 54%, 0.5)',
    },

    error: {
      contrast_text: colors.WHITE,
      text_dark: colors.ERROR_TEXT_DARK,
      main: colors.ERROR_MAIN,
      light: colors.ERROR_LIGHT,
      dark: colors.ERROR_DARK,
      light_bg: colors.ERROR_LIGHT_BG,
    },

    warning: {
      contrast_text: colors.PRIMARY,
      text_dark: colors.WARNING_TEXT_DARK,
      main: colors.WARNING_MAIN,
      dark: colors.WARNING_DARK,
      light: colors.WARNING_LIGHT,
      light_bg: colors.WARNING_LIGHT_BG,
    },

    info: {
      contrast_text: colors.WHITE,
      text_dark: colors.TEXT_DARK,
      main: colors.MAIN,
      dark: colors.DARK,
      light: colors.LIGHT,
      light_bg: colors.LIGHT_BG,
    },

    success: {
      contrast_text: colors.WHITE,
      text_dark: colors.TEXT_DARK,
      main: colors.SUCCESS_MAIN,
      dark: colors.SUCCESS_DARK,
      light: colors.SUCCESS_LIGHT,
      light_bg: colors.SUCCESS_LIGHT_BG,
    },

    grey: {
      grey_50: colors.KNOB_INACTIVEKNOB,
      grey_100: colors.GRAY_SCALE_1,
      grey_200: colors.GRAY_SCALE_2,
      grey_300: colors.GRAY_SCALE_3,
      grey_400: colors.GRAY_SCALE_4,
      grey_500: colors.GRAY_SCALE_5,
      grey_600: colors.GRAY_SCALE_6,
      grey_700: colors.GRAY_SCALE_7,
      grey_800: colors.GRAY_SCALE_8,
      grey_900: colors.GRAY_SCALE_9,
      black: colors.DARK,
      white: colors.GRAY_SCALE_0,
      grey_A100: colors.GREY_A100,
      grey_A200: colors.GREY_A200,
      grey_A400: colors.GRAY_SCALE_7,
      grey_A700: colors.GREY_A700,
    },

    // use color='textPrimary' for NAVY BLUE
    // use color='textSecondary' for WHITE
    text: {
      primary: colors.BLUE_BASE,
      secondary: colors.WHITE,
      disabled: colors.DISABLED_HINT,
      hint: colors.DISABLED_HINT,
    },

    action: {
      main: colors.GRAY_SCALE_3,
      hover: colors.HOVER,
      selected: colors.SELECTED,
      disabled_bg: colors.DISABLED_BG_FOCUS,
      disabled: colors.DISABLED,
      focus: colors.DISABLED_BG_FOCUS,
    },

    background: {
      paper: colors.GRAY_SCALE_0,
      surface: colors.GRAY_SCALE_1,
      alternative: colors.BLUE_LIGHT,
      default: colors.WHITE,
    },

    other: {
      border: colors.BORDER,
      backdrop: colors.BACKDROP,
      snackbar: colors.SNACKBAR,
      active_rating: colors.ACTIVE_RATING,
      active_loving: colors.ACTIVE_LOVING,
      divider: colors.DISABLED_BG_FOCUS,
      knob_inactive: colors.KNOB_INACTIVEKNOB,
    },

    dark: colors.DARK,
    light: colors.GRAY_SCALE_0,
  },

  typography: {
    fontFamily,
    fontWeights,
    fontSizes,
    lineHeights,
    letterSpacing,
    paragraphSpacing,
    // Hero Unit Titles
    h1: {
      fontFamily: fontFamily.heading,
      fontWeight: fontWeights.headingRegular,
      lineHeight: lineHeights.lineHeights_9,
      fontSize: fontSizes.fontSizes_5,
      '@media (min-width:600px)': {
        lineHeight: lineHeights.lineHeights_11,
        fontSize: fontSizes.fontSizes_7,
      },
    },
    // Headlines
    h2: {
      fontFamily: fontFamily.heading,
      fontWeight: fontWeights.iconSolid,
      lineHeight: lineHeights.lineHeights_10,
      fontSize: fontSizes.fontSizes_5,
    },
    // Article Titles
    h3: {
      fontFamily: fontFamily.label,
      fontWeight: fontWeights.iconSolid,
      lineHeight: lineHeights.lineHeights_12,
      fontSize: fontSizes.fontSizes_9,
      '@media (min-width:600px)': {
        lineHeight: lineHeights.lineHeights_9,
        fontSize: fontSizes.fontSizes_8,
      },
    },
    // Hero Unit Body Italic
    h4: {
      fontFamily: fontFamily.body,
      fontWeight: fontWeights.bodyLight,
      lineHeight: lineHeights.lineHeights_12,
      fontSize: fontSizes.fontSizes_2,
      fontStyle: 'italic',
      '@media (min-width:600px)': {
        lineHeight: lineHeights.lineHeights_9,
        fontSize: fontSizes.fontSizes_4,
      },
    },
    // Hero Unit Body light
    h5: {
      fontFamily: fontFamily.body,
      fontWeight: fontWeights.bodyLight,
      lineHeight: lineHeights.lineHeights_12,
      fontSize: fontSizes.fontSizes_2,
      '@media (min-width:600px)': {
        lineHeight: lineHeights.lineHeights_9,
        fontSize: fontSizes.fontSizes_3,
      },
    },
    h6: {
      fontFamily: fontFamily.heading,
      fontWeight: fontWeights.headingBold,
      lineHeight: lineHeights.lineHeights_5,
      fontSize: fontSizes.fontSizes_3,
      letterSpacing: letterSpacing.letterSpacing_4,
      paragraphSpacing: paragraphSpacing.paragraphSpacing_0,
    },
    // body
    body1: {
      fontFamily: fontFamily.body,
      fontWeight: fontWeights.bodyRegular,
      lineHeight: lineHeights.lineHeights_8,
      fontSize: fontSizes.fontSizes_0,
      '@media (min-width:600px)': {
        lineHeight: lineHeights.lineHeights_9,
        fontSize: fontSizes.fontSizes_3,
      },
    },
    // Secondary Article Body
    body2: {
      fontFamily: fontFamily.body,
      fontWeight: fontWeights.bodyRegular,
      lineHeight: lineHeights.lineHeights_12,
      fontSize: fontSizes.fontSizes_2,
    },
    // Subtitles
    subtitle1: {
      fontFamily: fontFamily.body,
      fontWeight: fontWeights.bodyRegular,
      lineHeight: lineHeights.lineHeights_9,
      fontSize: fontSizes.fontSizes_3,
    },
    // Article Date
    subtitle2: {
      fontFamily: fontFamily.body,
      fontWeight: fontWeights.bodyRegular,
      lineHeight: lineHeights.lineHeights_8,
      fontSize: fontSizes.fontSizes_0,
    },
    // Article body
    caption: {
      fontFamily: fontFamily.body,
      fontWeight: fontWeights.bodyRegular,
      fontSize: fontSizes.fontSizes_1,
      '@media (min-width:600px)': {
        fontSize: fontSizes.fontSizes_2,
      },
    },
    overline: {
      fontFamily: fontFamily.body,
      fontWeight: fontWeights.bodyRegular,
      lineHeight: lineHeights.lineHeights_11,
      fontSize: fontSizes.fontSizes_0,
      letterSpacing: letterSpacing.letterSpacing_7,
      paragraphSpacing: paragraphSpacing.paragraphSpacing_0,
    },
    button: {
      fontFamily: fontFamily.body,
      fontWeight: fontWeights.bodyMedium,
      lineHeight: lineHeights.lineHeights_12,
      fontSize: fontSizes.fontSizes_2,
      textTransform: 'Capitalize',
    },
    buttonLg: {
      fontFamily: fontFamily.label,
      fontWeight: fontWeights.labelBold,
      lineHeight: lineHeights.lineHeights_12 + 2,
      fontSize: fontSizes.fontSizes_1 + 10,
      letterSpacing: letterSpacing.letterSpacing_6,
      paragraphSpacing: paragraphSpacing.paragraphSpacing_0,
    },
    buttonSm: {
      fontFamily: fontFamily.label,
      fontWeight: fontWeights.labelRegular,
      lineHeight: lineHeights.lineHeights_12 - 2,
      fontSize: fontSizes.fontSizes_1 - 1,
      letterSpacing: letterSpacing.letterSpacing_6,
      paragraphSpacing: paragraphSpacing.paragraphSpacing_0,
    },
    code: {
      fontFamily: fontFamily.code,
      fontWeight: fontWeights.bodyBold,
      lineHeight: lineHeights.body,
      fontSize: fontSizes.sm,
    },
    icon3xlarge: {
      fontFamily: fontFamily.icon,
      fontWeight: fontWeights.iconDefault,
      lineHeight: fontSizes.icon3xl,
      fontSize: fontSizes.icon3xl,
    },
    icon2xlarge: {
      fontFamily: fontFamily.icon,
      fontWeight: fontWeights.iconDefault,
      lineHeight: fontSizes.icon2xl,
      fontSize: fontSizes.icon2xl,
    },
    iconXlarge: {
      fontFamily: fontFamily.icon,
      fontWeight: fontWeights.iconDefault,
      lineHeight: fontSizes.iconXl,
      fontSize: fontSizes.iconXl,
    },
    iconXlargeSolid: {
      fontFamily: fontFamily.icon,
      fontWeight: fontWeights.iconSolid,
      lineHeight: fontSizes.iconXl,
      fontSize: fontSizes.iconXl,
    },
    iconLarge: {
      fontFamily: fontFamily.icon,
      fontWeight: fontWeights.iconDefault,
      lineHeight: fontSizes.iconLg,
      fontSize: fontSizes.iconLg,
    },
    iconLargeSolid: {
      fontFamily: fontFamily.icon,
      fontWeight: fontWeights.iconSolid,
      lineHeight: fontSizes.iconLg,
      fontSize: fontSizes.iconLg,
    },
    iconMedium: {
      fontFamily: fontFamily.icon,
      fontWeight: fontWeights.iconDefault,
      lineHeight: fontSizes.iconMd,
      fontSize: fontSizes.iconMd,
    },
    iconMediumSolid: {
      fontFamily: fontFamily.icon,
      fontWeight: fontWeights.iconSolid,
      lineHeight: fontSizes.iconMd,
      fontSize: fontSizes.iconMd,
    },
    iconSmall: {
      fontFamily: fontFamily.icon,
      fontWeight: fontWeights.iconDefault,
      lineHeight: fontSizes.iconSm,
      fontSize: fontSizes.iconSm,
    },
    iconSmallSolid: {
      fontFamily: fontFamily.icon,
      fontWeight: fontWeights.iconSolid,
      lineHeight: fontSizes.iconSm,
      fontSize: fontSizes.iconSm,
    },
    iconXsmall: {
      fontFamily: fontFamily.icon,
      fontWeight: fontWeights.iconDefault,
      lineHeight: fontSizes.iconXs,
      fontSize: fontSizes.iconXs,
    },
    iconXsmallSolid: {
      fontFamily: fontFamily.icon,
      fontWeight: fontWeights.iconSolid,
      lineHeight: fontSizes.iconXs,
      fontSize: fontSizes.iconXs,
    },
  },

  overrides: {
    MuiCssBaseline: {
      '@global': {
        '@font-face': [concordeBq],
      },
    },
  },
}
// A custom theme for this app
const theme = createTheme(properties)

export default theme
